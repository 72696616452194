<template>
    <div class="rno-pages-wrap" v-show="pageSize>1">
        <div class="rno-pages">
            <a :class="['rno-pages-item','rno-pages-item-prev',{'pre-disabled':prevDisable}]" @click.prevent="jumpPrev()" href="javascript:void(0)">上一页</a>

            <template v-for="i in pageSize">
                <li class="rno-pages-item rno-pages-item-active" v-if="i==pageNo">
                    <a href="javascript:void(0)">{{i}}</a>
                </li>
                <li class="rno-pages-item" v-else-if="pageNo<5&&(i)<6" @click.prevent="jump(i)">
                    <a href="javascript:void(0)">{{i}}</a>
                </li>
                <li class="rno-pages-item" v-else-if="pageSize<7||i==1||i==pageSize||(pageNo-2<=i&&i<=pageNo+2)" @click.prevent="jump(i)">
                    <a href="javascript:void(0)">{{i}}</a>
                </li>
                <template v-else>
                    <li v-if="pageNo<5&&i==6"  class="rno-pages-item disabled"><a href="javascript:void(0)">...</a></li>
                    <li v-if="pageNo==4&&i==7"  class="rno-pages-item disabled"><a href="javascript:void(0)">...</a></li>
                    <li v-if="pageNo>4&&i==pageNo-3"  class="rno-pages-item disabled"><a href="javascript:void(0)">...</a></li>
                    <li v-if="pageNo>4&&i==pageNo+3"  class="rno-pages-item disabled"><a href="javascript:void(0)">...</a></li>
                </template>
            </template>
            
            <a :class="['rno-pages-item','rno-pages-item-next',{'next-disabled':nextDisable}]" @click.prevent="jumpNext()" href="javascript:void(0)" >下一页</a>
        </div>
    </div>
</template>

<script>
export default {
    model:{
        prop: 'pageNo'
    },
    props:{
        pageSize:{
            type: Number,
            default: 1
        },
        pageNo:{
            type: Number,
            default: 1
        },
        pageName : ''
    },
    data(){
        return {
        }
    },
    computed: {
        prevDisable: function(){ //“上一页”按钮是否可点
            if(this.pageNo > 1){
                console.log('this.pageNo', this.pageNo)
                return false;
            }else{
                return true
            }
        },
        nextDisable: function(){ //“下一页”按钮是否可点
            if(this.pageNo < this.pageSize && this.pageSize > 1){
                return false;
            }else{
                return true;
            }
        },
    },
    methods: {
        jumpPrev: function(){ //点击上一页
            if(this.pageNo == 1){
                return ;
            }else{
                //this.$emit('jumpPage',this.pageNo-1);
                this.$emit('on-jump',this.pageNo-1, this.pageName);
            }
        },
        jumpNext: function(){ //点击下一页
            if(this.pageNo == this.pageSize){
                return ;
            }else{
                //this.$emit('jumpPage',this.pageNo+1); //修改当前页码
                this.$emit('on-jump',this.pageNo+1, this.pageName); //跳转
            }
        },
        jump: function(id){ //直接跳转
            if(id>this.pageSize){
                id=this.pageSize;
            }
            //this.jumpPage = '';
            //this.$emit('jumpPage',id); //修改当前页码
            this.$emit('on-jump',id, this.pageName); //跳转
        }
    }
}
</script>
<style lang="css" scoped>
.rno-pages-item {
    cursor: pointer;
    color: #666;
}
.rno-pages-item:hover a{
    color: #2b2b2b;
    text-decoration: none;
}
.rno-pages-item a{
    cursor: pointer;
    color: #666;
}
.pre-disabled:hover,.next-disabled:hover {
    color: #666;
    border: 1px solid #ddd;
    text-decoration: none;
    cursor: not-allowed;
}
.pre-disabled:hover:before {
    background-position: 90.91% 45.12%;
}
.next-disabled:hover:before {
    background-position: 90.91% 1.22%;
}
</style>