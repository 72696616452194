<template src="./index.html">
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import iframeObj from '../../util/iframe'
import NewPageBox from '_c/NewPageBox'
import { getAddressName } from '../../util/getAddressName'

export default {
  async asyncData ({ store, route }) {
    const DISPATCH = store.dispatch
    const PARAMS = store.state.route.params
    let result = await DISPATCH('dataProvider/getProviderDetail', {id : parseInt(PARAMS.id)})
    const detail = result && result.listData.data || {}
    return await Promise.all([
      DISPATCH('dataProvider/getRecommendProviders', {
        Uin: detail.uin + '',
        SubAccountUin: detail.subAccountUin + '',
				Category1Id: detail.serviceType,
				Offset: 0,
        Limit: 3,
      }),
      DISPATCH('dataProvider/getTerminalList', {
        spUin: detail.uin + '',
        spSubAccountUin: detail.subAccountUin + '',
        offset: 0,
        limit: 12,
      }),
      DISPATCH('dataProvider/getDeviceList', {
        spUin: detail.uin + '',
        spSubAccountUin: detail.subAccountUin + '',
        offset: 0,
        limit: 12,
      }),
      DISPATCH('dataProvider/getSolutionList', {
        spUin: detail.uin + '',
        spSubAccountUin: detail.subAccountUin + '',
        offset: 0,
        limit: 12,
      })
    ])
  },
  components:{
    NewPageBox,
  },
  data() {
    return {
      currentTab: 1,
      providerPageNo: 1,
    }
  },
  computed: {
     ...mapState('dataProvider',[
       'providerDetail',
       'terminalList',
       'deviceList',
       'solutionList',
       'recommendProviders',
     ]),
     List() {
       switch (this.currentTab) {
         case 2:
          return this.terminalList
          break;
         case 3:
          return this.deviceList
           break;
         case 4:
           return this.solutionList
           break;
         default:
           return []
           break;
       }
     },
     pageSize() {
       const pagesize = this.List.total/12 + 1
       return parseInt(pagesize)
     }
  },
  methods: {
    ...mapActions('dataProvider', [
      'getProviderDetail',
      'getTerminalList',
      'getDeviceList',
      'getSolutionList',
      'getRecommendProviders',
    ]),
    getCityName(pid, cid){
			const city = getAddressName(pid, cid).city
      return city
		},
    go_detail(id) {
      window.location.href = `//iot.cloud.tencent.com/market/providerdetail/${id}`
    },
    open_page(url) {
      window.open(url)
    },
    click_page(pageNum, pageName) {
      const offset = (pageNum-1)*10
      this.providerPageNo = pageNum
      const params = {
        offset,
      }
      switch (this.currentTab) {
        case 2:
          this.get_terminalList(params)
          break;
        case 3:
          this.get_deviceList(params)
          break;
        case 4:
          this.get_solutionList(params)
          break;
        default:
          break;
      }
    },
    change_tab(value) {
      this.currentTab = value
    },
    get_terminalList(param) {
      const params = {
        spUin: this.providerDetail.uin + '',
        spSubAccountUin: this.providerDetail.subAccountUin + '',
        offset: 0,
        limit: 12,
        ...param
      }
      this.getTerminalList(params)
    },
    get_deviceList(param) {
      const params = {
        spUin: this.providerDetail.uin + '',
        spSubAccountUin: this.providerDetail.subAccountUin + '',
        offset: 0,
        limit: 12,
        ...param
      }
      this.getDeviceList(params)
    },
    get_solutionList(param) {
      const params = {
        uin: this.providerDetail.uin + '',
        subAccountUin: this.providerDetail.subAccountUin + '',
        offset: 0,
        limit: 12,
        ...param
      }
      this.getSolutionList(params)
    },
  },
  mounted() {
    this.providerDetail.productDomain && iframeObj.setIframeContent('iframebox', this.providerDetail.productDomain)
  },
}
</script>
<style lang="less" scoped>
.market-contact-text-span:hover {
  color: #00a4ff
}
</style>